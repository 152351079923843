import { gql, useMutation } from "@apollo/client"
import { css } from "@emotion/core"
import { Container, FormControlLabel, Paper } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import FormHelperText from "@material-ui/core/FormHelperText"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Field, Form, Formik } from "formik"
import { CheckboxWithLabel, TextField } from "formik-material-ui"
import { Link, navigate } from "gatsby"
import React, { useEffect } from "react"
import uuid from "uuid/v4"
import * as Yup from "yup"
import { useAuth } from "../hooks/useAuth"
import { setAuthToken, setRefreshToken } from "../services/auth"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(80, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(80, "Too Long!")
    .required("Required"),
  organization: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  checkedNewsletter: Yup.boolean(),
  checkedPolicy: Yup.boolean().oneOf(
    [true],
    "You have to confirm our policy to proceed."
  ),
})

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 640,
    padding: theme.spacing(4),
    margin: `${theme.spacing(4)}px 0`,
  },
  textFieldFull: {
    width: "100%",
  },
  checkBoxes: {
    marginTop: 32,
    marginBottom: 24,
  },
  checkBoxSpacing: {
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 11,
  },
  flexStart: {
    alignItems: "flex-start",
  },
  checkbox: {
    marginTop: -8,
  },
  errorText: {
    fontSize: 16,
    marginLeft: 58,
  },
}))

const REGISTER_USER = gql`
  mutation RegisterUser($input: RegisterCustomerInput!) {
    registerCustomer(input: $input) {
      authToken
      refreshToken
      customer {
        displayName
        customerId
        firstName
        lastName
      }
    }
  }
`

const Signup = () => {
  const auth = useAuth()

  useEffect(() => {
    if (auth.isLoggedIn()) {
      navigate(`/dashboard/`, { replace: true })
    }
  }, [auth])

  const classes = useStyles()

  const [
    registerUser,
    { data, error: signUpError, loading: signUpLoading },
  ] = useMutation(REGISTER_USER)

  signUpError && console.log({ signUpError })

  return (
    <Layout>
      <Seo title={"Sign Up"} />

      <Container
        maxWidth={"xl"}
        css={css`
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          margin-top: 32px;
        `}
      >
        <Paper className={classes.root}>
          <Typography variant={"h3"} align={"center"}>
            Sign Up
          </Typography>
          <Typography
            variant={"subtitle1"}
            component={"p"}
            css={css`
              padding-bottom: 32px;
            `}
            align={"center"}
          >
            Not yet registered? Sign up now.
          </Typography>
          <Formik
            initialValues={{
              organization: "",
              email: "",
              password: "",
              firstName: "",
              lastName: "",
              checkedNewsletter: false,
              checkedPolicy: false,
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
              // console.log("Input", values)
              registerUser({
                variables: {
                  input: {
                    clientMutationId: uuid(),
                    username: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    password: values.password,
                    billing: {
                      company: values.organization,
                    },
                    shippingSameAsBilling: true,
                  },
                },
              }).then(response => {
                // console.log(response)
                setAuthToken(response.data.registerCustomer.authToken)
                setRefreshToken(
                  response.data.registerCustomer.refreshToken,
                  () => navigate("/dashboard/")
                )
                setSubmitting(false)
              })
            }}
          >
            {({ submitForm, isSubmitting, values, setFieldValue, errors }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      className={classes.textFieldFull}
                      name="email"
                      type="email"
                      label="Email"
                      component={TextField}
                      variant={"outlined"}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      className={classes.textFieldFull}
                      type="password"
                      label="Password"
                      name="password"
                      component={TextField}
                      variant={"outlined"}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.textFieldFull}
                      name="firstName"
                      type="string"
                      label="Firstname"
                      component={TextField}
                      variant={"outlined"}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.textFieldFull}
                      name="lastName"
                      type="string"
                      label="Lastname"
                      component={TextField}
                      variant={"outlined"}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      className={classes.textFieldFull}
                      name="company"
                      type="string"
                      label="Organization"
                      component={TextField}
                      variant={"outlined"}
                    />
                  </Grid>
                </Grid>

                <div className={classes.checkBoxes}>
                  <FormControlLabel
                    className={`${classes.checkBoxSpacing} ${classes.flexStart}`}
                    label={
                      <Typography variant={"body2"}>
                        I agree to the NeverNull{" "}
                        <a
                          href={"https://mobileui.dev/terms-of-service"}
                          target={"_blank"}
                          rel="noopener noreferrer"
                        >
                          terms of service
                        </a>{" "}
                        and{" "}
                        <a
                          href={"https://mobileui.dev/privacy-policy"}
                          target={"_blank"}
                          rel="noopener noreferrer"
                        >
                          privacy policy
                        </a>
                        .
                      </Typography>
                    }
                    control={
                      <Field
                        className={classes.checkbox}
                        id="checkedPolicy"
                        name="checkedPolicy"
                        type="checkbox"
                        color={"primary"}
                        required
                        component={CheckboxWithLabel}
                      />
                    }
                  />
                  <FormHelperText className={classes.errorText} error required>
                    {errors?.checkedPolicy}&nbsp;
                  </FormHelperText>

                  {/*<FormControlLabel*/}
                  {/*  className={`${classes.checkBoxSpacing} ${classes.flexStart}`}*/}
                  {/*  control={*/}
                  {/*    <Field*/}
                  {/*      type="checkbox"*/}
                  {/*      className={classes.checkbox}*/}
                  {/*      label={{ label: "Newsletter Check" }}*/}
                  {/*      name="checkedNewsletter"*/}
                  {/*      id="checkedNewsletter"*/}
                  {/*      component={Checkbox}*/}
                  {/*    />*/}
                  {/*  }*/}
                  {/*  label={*/}
                  {/*    <Typography variant={"body2"}>*/}
                  {/*      Yes, I allow NeverNull GmbH to send me a newsletter about*/}
                  {/*      MobileUI to stay informed about updates and development of*/}
                  {/*      the framework. See more info about the intended content of*/}
                  {/*      the newsletter here.*/}
                  {/*    </Typography>*/}
                  {/*  }*/}
                  {/*/>*/}
                  {/*<Field id="checkedPolicy" name="checkedPolicy" className={classes.checkBoxSpacing}>*/}
                  {/*  {props => (*/}
                  {/*    <ValidatedCheckbox*/}
                  {/*      classFormControl={classes.flexStart}*/}
                  {/*      classCheckbox={classes.checkbox}*/}
                  {/*      label="Policy text ... Yes, I allow NeverNull GmbH to send me a newsletter about MobileUI to stay informed about updates and development"*/}
                  {/*      {...props}*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*</Field>*/}
                </div>

                <Button
                  type={"submit"}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  size={"large"}
                  fullWidth
                >
                  Sign Up
                </Button>
              </Form>
            )}
          </Formik>
          {signUpError && (
            <Typography
              color={"error"}
              css={css`
                margin-top: 32px;
              `}
            >
              {signUpError?.graphQLErrors?.[0]?.message ??
                "Something went wrong"}
            </Typography>
          )}
          <Typography
            variant={"body2"}
            style={{ textAlign: "center", marginTop: 16 }}
          >
            Already have an account? Please go to the{" "}
            <Link to={"/login/"}>Login Page</Link>.
          </Typography>
        </Paper>
      </Container>
    </Layout>
  )
}

export default Signup
